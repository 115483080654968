import type { Dispatch, SetStateAction } from "react";
import { createContext, useMemo, useState } from "react";

type ContextProps = {
    storeData: any;
    setStoreData: Dispatch<SetStateAction<any>>;
};

export const ClickAndCollectController = createContext<ContextProps>({
    storeData: "",
    setStoreData: () => {},
});

type Props = {
    children: JSX.Element;
};

export const ClickAndCollectContext = ({ children }: Props) => {
    const [storeData, setStoreData] = useState<any>([]);
    const contextValue = useMemo(() => {
        return {
            storeData,
            setStoreData,
        };
    }, [storeData, setStoreData]);
    return (
        <ClickAndCollectController.Provider value={contextValue}>
            {children}
        </ClickAndCollectController.Provider>
    );
};
